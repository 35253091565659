// See https://github.com/danigb/soundfont-player
// for more documentation on prop options.
import React from "react";
import PropTypes, { func } from "prop-types";
import Soundfont from "soundfont-player";
import { getQueryVariable, simulateKeyPress } from "./Utils";
import { socketEmit, socketOn } from "../socket";


let roomName = '';

let getRoom = getQueryVariable("roomName");
let _getRoom = getQueryVariable("room_name");
if (getRoom != '' && getRoom != undefined) {
    roomName = "/" + getRoom;
} else if (_getRoom != '' && _getRoom != undefined) {
    roomName = "/" + _getRoom;
}


//let location = "https://uat.realtimeaudio.com" + roomName;
let location = "https://web.oneclickwebcam.com" + roomName;


class SoundfontProvider extends React.Component {

    static propTypes = {
        instrumentName: PropTypes.string.isRequired,
        hostname: PropTypes.string.isRequired,
        format: PropTypes.oneOf(["mp3", "ogg"]),
        soundfont: PropTypes.oneOf(["MusyngKite", "FluidR3_GM"]),
        audioContext: PropTypes.instanceOf(window.AudioContext),
        render: PropTypes.func
    };

    static defaultProps = {
        format: "mp3",
        soundfont: "MusyngKite",
        instrumentName: "acoustic_grand_piano"
    };

    constructor(props) {
        super(props);
        this.state = {
            activeAudioNodes: {},
            instrument: null,
            notePlay: Number
        };
    }

    // componentWillMount() {
    //     this.loadInstrument(this.props.instrumentName);
    // }

    componentDidMount() {
        this.loadInstrument(this.props.instrumentName);

        const { socket, Sessions, closeTool, customKeys } = this.props;


        socketOn("played_note_return", data => {
            console.log("received ", data)
            if (data.location == location) {
                console.log("socket received played_note_return, sender ", data.SessionId, "receiver ", Sessions.SessionId)
                if (data.SessionId != Sessions.SessionId) {
                    let type = data.key;
                    simulateKeyPress(customKeys, data.note, type);
                    if (type == "keydown") {
                        this.playNote(data.note, false)
                    } else {
                        this.stopNote(data.note, false)
                    }
                }
            }
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.instrumentName !== this.props.instrumentName) {
            this.loadInstrument(this.props.instrumentName);
        }
    }

    loadInstrument = instrumentName => {
        // Re-trigger loading state
        // this.setState({
        //     instrument: null
        // });

        Soundfont.instrument(this.props.audioContext, instrumentName, {
            format: this.props.format,
            soundfont: this.props.soundfont,
            nameToUrl: (name, soundfont, format) => {
                return `${this.props.hostname}/${soundfont}/${name}-${format}.js`;
            }
        }).then(instrument => {
            // let d = instrument; // play note manually
            // d.play(25);
            this.setState({
                instrument
            });
        })
    };

    playNote = (midiNumber, is_first = true) => {

        const { socket, Sessions } = this.props;


        this.props.audioContext.resume().then(() => {
            const audioNode = this.state.instrument.play(midiNumber);
            this.setState({
                activeAudioNodes: Object.assign({}, this.state.activeAudioNodes, {
                    [midiNumber]: audioNode
                })
            });

            if (is_first) {
                console.log("session make key down", this.props.Sessions)
                let send = {
                    SessionId: Sessions.SessionId,
                    note: midiNumber,
                    key: "keydown",
                    location
                }
                socketEmit("played_note_send", send)

                console.log("send ", send)
            }
        });

    };

    stopNote = (midiNumber, is_first = true) => {
        const { socket, Sessions } = this.props;

        this.props.audioContext.resume().then(() => {
            if (!this.state.activeAudioNodes[midiNumber]) {
                return;
            }
            const audioNode = this.state.activeAudioNodes[midiNumber];
            audioNode.stop();
            this.setState({
                activeAudioNodes: Object.assign({}, this.state.activeAudioNodes, {
                    [midiNumber]: null
                })
            });
            if (is_first) {
                console.log("session make key up", this.props.Sessions)

                let send = {
                    SessionId: this.props.Sessions.SessionId,
                    note: midiNumber,
                    key: "keyup",
                    location
                }
                socketEmit("played_note_send", send)
                console.log("send ", send)

            }
        });
    };

    // Clear any residual notes that don't get called with stopNote
    stopAllNotes = () => {
        this.props.audioContext.resume().then(() => {
            const activeAudioNodes = Object.values(this.state.activeAudioNodes);
            activeAudioNodes.forEach(node => {
                if (node) {
                    node.stop();
                }
            });
            this.setState({
                activeAudioNodes: {}
            });
        });
    };

    render() {
        return this.props.render({
            isLoading: !this.state.instrument,
            playNote: this.playNote,
            stopNote: this.stopNote,
            stopAllNotes: this.stopAllNotes
        });
    }
}

export default SoundfontProvider;